import React from "react";
import three from '../images/three.png'
import two from '../images/two.png'
import one from '../images/one.png'

const About = () => {
    const payload = [
        {
            title: "User Friendly Interface for you",
            icon: one,
            detail: "With our product interfaces, buying and selling is easier"
        },
        {
            title: "Top Rated Sellers for you",
            icon: two,
            detail: "Millions of buyers ready to buy your products"
        },
        {
            title: "Best Prices on every products",
            icon: two,
            detail: "Something to cheer you up when buying from QUID - Best prices"
        },
        {
            title: "Millions of Buyers for every products",
            icon: three,
            detail: "Something to cheer you up when buying from QUID - Best prices"
        },
    ]
    return (
        <section className="bg-about py-10 px-10" id="contact">
            <div className="text-left mx-auto text-white">
                <p className="text-aboutQuestion lg:text-2xl mb-5 mx-auto pb-2 font-normal"> Why Quid?</p>
                <h3 className="text-2xl md:text-2xl lg:text-5xl text-white font-black mb-2 mx-auto pb-2 leading-relaxed">
                    A Virtual Marketplace Created To Make <br /><b className="text-aboutQuestion2">Buying And Selling</b> Fun And Easy </h3>
                    <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 gap-2 content-normal">
                    {payload && payload.length > 0 && payload?.map((data, index) =>

                        <div class="max-w-sm border-2 border-heroheader bg-white  dark:bg-black rounded-lg shadow flex flex-col  mx-auto">
                            <div class="rounded-t-lg bg-aboutQuestion h-40" >
                                <img src={data?.icon} className="object-right-top p-4 object-scale-down" />
                            </div>
                            <div class="p-5 bg-white h-30 text-heroheader dark:text-white dark:bg-black">
                                <h5 class="mb-2 text-2xl lg:text-4xl font-bold tracking-tight">{data?.title}</h5>
                                <p class="mb-3 font-normal text-l lg:text-xl">{data?.detail}</p>
                                <p className="border-b-[5px] w-[200px] border-aboutQuestion pb-2 mb-auto"></p>
                            </div>
                        </div>
                    )}
                </div>
                    </div>
        </section>
    );
};

export default About;