import React from "react";
import facebook from '../images/facebook.png'
import twitter from '../images/twitter.png'
import instagram from '../images/instagram.png'

const Contact = () => {
    return (
        <section className="bg-secondery px-2 pt-20 pb-5 dark:text-white dark:bg-black" id="contact">
            <div className="text-center md:w-[70%] mx-auto">
                <img className="h-8 mx-auto mb-6" src="https://ik.imagekit.io/ffnwbq9cnh/prod/product_assets/logo_horizontal.png?updatedAt=1687430923433" alt="logo"/>

                <p className="lg:text-2xl mb-5 mx-auto pb-2 font-normal">
                    Connect with us on social:
                </p>
                <div className="flex space-x-1 items-center justify-center">
                    <img src={facebook} className="flex space-x-1" alt="facebook" />
                    <img src={twitter} className="flex space-x-1" alt="twitter" />

                    <img src={instagram} className="flex space-x-1" alt="instagram" />
                </div>
            </div>
        </section>
    );
};

export default Contact;