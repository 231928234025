import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [year, setYear] = useState();
  useEffect(() => {
    const d = new Date();
    let presentYear = d.getFullYear();
    setYear(presentYear);
  }, []);

  return (
    <section
      className="bg-secondery px-1 lg:px-10 py-1 dark:text-white dark:bg-black flex justify-between"
      id="contact"
    >
      <p className="lg:text-lg mb-5 pb-2 font-normal">
        Copyrights © {year} OPHION356 LIMITED. All Rights Reserved
      </p>
      <Link to={"/privacypolicy"} className="text-lg cursor-pointer underline">Privacy Policy</Link>
    </section>
  );
};

export default Footer;
