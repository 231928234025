import Header from '../components/header'
import Hero from '../components/hero'
import About from '../components/about'
import Space from '../components/space'
import Download from '../components/download'
import Contact from '../components/contact'
import Footer from '../components/footer'
import Faqs from '../components/faqs'

export default function Dashboard() {
  return (
    <div className='font-display'>
      <Header />
      <Hero />
      <About />
      <Space colour ="bg-aboutQuestion2"/>
      <Faqs/>
      <Download/>
      <Space colour ="bg-download2"/>
      <Contact/>
      <Footer/>
    </div>
  )
}
