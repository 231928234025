import React from "react";
import bag from '../images/bag.png'
import applestore from '../images/logo-apple-appstore.png'
import playstore from '../images/logo-google-playstore.png'

const Download = () => {
    return (
        <section className="bg-gradient-to-b from-download to-download3" id="download">
            <div className=" text-center lg:text-left mx-auto text-white">
                <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-3 gap-4 ">
                    <div className="col-span-2 my-auto py-5 px-5 lg:py-10 lg:px-10">
                        <h3 className="text-3xl md:text-2xl lg:text-5xl text-white font-black mb-2 mx-auto pb-2 leading-relaxed">
                            Get started in seconds and experience a new way of buying and sellings</h3>

                        <p className="lg:text-2xl text-white mb-5 mx-auto pb-2 font-normal mt-7">
                            Available on all stores now!
                        </p>

                        <div className="flex space-x-1 items-center justify-center lg:justify-start lg:items-left">
                            <a href=" https://play.google.com/store/apps/details?id=com.qiid.app" target='_blank'>
                                <button class="flex space-x-1 bg-white text-download text-left py-2 px-6 rounded-full border border-aboutQuestion dark:text-white dark:bg-black">
                                    <img src={playstore} className="" />
                                    <p className="text-xs lg:text-base md:text-base"> Download on <br /><b>Play Store</b></p>
                                </button>
                            </a>
                            <a href="https://apps.apple.com/ng/app/quid/id1661359725" target='_blank'>
                                <button class="flex space-x-1 bg-white text-download text-left py-2 px-6 rounded-full border border-aboutQuestion dark:text-white dark:bg-black">
                                    <img src={applestore} />
                                    <p className="text-xs lg:text-base md:text-base"> Download on <br /><b>Apple Store</b></p>
                                </button>
                            </a>
                        </div>
                    </div>
                    <img src={bag} alt="" className="mx-auto items-center " />
                </div>
            </div>
        </section>
    );
};

export default Download;