import React from "react";

const Space = ({colour}) => {
    return (
        <section className={colour} id="space">
            <div className="h-10">

            </div>
        </section>
    );
};

export default Space;