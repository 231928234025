import React from "react";
import Header from "../components/header";
import Space from "../components/space";
import Contact from "../components/contact";
import Footer from "../components/footer";

const PrivacyPolicy = () => {
  return (
    <div className="font-display bg-white text-black dark:bg-black dark:text-white">
      <Header />

      <div className="py-8 px-8 lg:px-20">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy for Quid</h1>
        <p className="text-sm mb-6">
          <strong>Last Updated:</strong> 07/07/2024
        </p>

        <p className="mb-4">
          Quid ("we", "our", or "us") is committed to protecting and respecting
          your privacy. This Privacy Policy explains how we collect, use, and
          protect your personal information when you use our ecommerce mobile
          app ("App"). It also explains your rights regarding your personal
          data.
        </p>

        <p className="mb-4">
          By using the App, you agree to the collection and use of information
          in accordance with this policy.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          1. Information We Collect
        </h2>
        <p className="mb-4">We collect the following types of information:</p>

        <h3 className="text-xl font-semibold mb-2">a. Personal Information</h3>
        <p className="mb-2">
          When you use the App, we may collect personal information that you
          provide to us, including but not limited to:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Billing and shipping address</li>
          <li>Payment details (processed via third-party payment providers)</li>
          <li>Phone number</li>
          <li>Username and password</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">
          b. Automatically Collected Information
        </h3>
        <p className="mb-2">
          When you use the App, we automatically collect certain information
          about your device and how you interact with the App, including:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>IP address</li>
          <li>Device type, operating system, and browser type</li>
          <li>
            Usage data, including pages viewed, interactions, and time spent on
            the App
          </li>
          <li>
            Cookies and similar tracking technologies (see section on Cookies
            below)
          </li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">c. Location Data</h3>
        <p className="mb-4">
          With your consent, we may collect and process location information to
          improve the user experience and provide location-based services.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          2. How We Use Your Information
        </h2>
        <p className="mb-4">
          We may use your information for the following purposes:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>To process and fulfill your orders</li>
          <li>To communicate with you regarding your account or orders</li>
          <li>To improve the App and your user experience</li>
          <li>To comply with legal obligations</li>
          <li>To send you marketing communications, with your consent</li>
          <li>To detect, prevent, and address technical issues or fraud</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">
          3. Sharing Your Information
        </h2>
        <p className="mb-4">
          We may share your information with third parties under the following
          circumstances:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>Service Providers:</strong> We use third-party service
            providers to help operate the App and provide our services, such as
            payment processors, delivery services, and IT support.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may disclose your information
            if required by law, regulation, or legal process.
          </li>
          <li>
            <strong>Business Transfers:</strong> If we are involved in a merger,
            acquisition, or sale of assets, your personal information may be
            transferred to the new owner.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">
          4. Cookies and Tracking Technologies
        </h2>
        <p className="mb-4">
          We use cookies and similar tracking technologies to enhance your
          experience on the App. These may include:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>Essential Cookies:</strong> Required for the App to function
            properly.
          </li>
          <li>
            <strong>Performance Cookies:</strong> Help us understand how users
            interact with the App.
          </li>
          <li>
            <strong>Targeting Cookies:</strong> Used to deliver relevant
            advertisements based on your interests.
          </li>
        </ul>
        <p className="mb-4">
          You can manage your cookie preferences through your device or browser
          settings.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
        <p className="mb-4">
          We retain your personal data only for as long as necessary to fulfill
          the purposes outlined in this policy, including to comply with legal,
          accounting, or reporting requirements.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          6. Your Data Protection Rights
        </h2>
        <p className="mb-4">
          Under the UK General Data Protection Regulation (GDPR), you have
          certain rights regarding your personal data, including:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>Right of Access:</strong> You can request access to your
            personal data that we hold.
          </li>
          <li>
            <strong>Right to Rectification:</strong> You can request corrections
            to any inaccurate or incomplete data.
          </li>
          <li>
            <strong>Right to Erasure:</strong> You can request that we delete
            your personal data, subject to certain legal obligations.
          </li>
          <li>
            <strong>Right to Restrict Processing:</strong> You can request the
            restriction of processing your personal data under certain
            conditions.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You can request that we
            transfer your data to another organization.
          </li>
          <li>
            <strong>Right to Object:</strong> You can object to the processing
            of your personal data for direct marketing purposes.
          </li>
          <li>
            <strong>Right to Withdraw Consent:</strong> You can withdraw your
            consent to data processing at any time.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">
          7. Security of Your Data
        </h2>
        <p className="mb-4">
          We take the security of your personal information seriously and
          implement appropriate technical and organizational measures to protect
          your data from unauthorized access, alteration, or disclosure.
          However, no method of transmission over the Internet is 100% secure,
          and we cannot guarantee absolute security.
        </p>

        <h2 className="text-2xl font-semibold mb-4">
          8. Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the "Last Updated" date will be revised
          accordingly. We encourage you to review this policy periodically for
          any updates.
        </p>

        <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about this Privacy Policy or how
          we handle your personal data, please contact us at:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>Email:</strong> admin@shopquid.com
          </li>
          <li>
            <strong>Address:</strong> 27 Cranswick Grove, UK
          </li>
        </ul>

        <p>Thank you for using Quid.</p>
      </div>
      <Space colour="bg-download2" />
      <Contact />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
