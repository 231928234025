import React from "react";
import help from '../images/help.png'
import { Accordion } from "flowbite-react";
const Faqs = () => {
    return (
        <section className="bg-white dark:bg-black px-2 py-10" id="contact">
            <div className="text-left md:w-[70%] p-4 border-4 border-black rounded-lg shadow mx-auto text-black dark:text-white dark:border-white">
                <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap -m-8">
                        <div class="w-full text-left md:w-1/2 p-8">
                            <p>FAQS</p>
                            <h3 className="text-3xl md:text-2xl lg:text-3xl text-black font-black mx-auto leading-relaxed dark:text-white">
                                Get to know us better</h3>
                            <img src={help} alt="" className="hidden lg:block md:block" />
                        </div>
                        <div class="w-full md:w-1/2 p-8 mx-auto">
                        <Accordion collapseAll>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                        <h2 className="text-black dark:text-white ">What is QUID?</h2>   
                                    </Accordion.Title>
                                    <Accordion.Content className="dark:bg-black">
                                        <p className="mb-2 text-black dark:text-white ">
                                            <p>
                                                Quid is a global virtual marketplace created to make buying and selling easy, safe, secure, and effective. QUID is on a mission to transform the Nigerian e-commerce sector with advanced innovation and technology.
                                            </p>
                                        </p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                    <h2 className="text-black dark:text-white "> How do i place an order on QUID?</h2>                                        
                                    </Accordion.Title>
                                    <Accordion.Content className="dark:bg-black">
                                        <p class="mb-2 text-black dark:text-white ">Placing an order on QUID is an easy process…Let's walk you through it</p>
                                        <ol className="pl-5 text-black dark:text-white  list-disc">
                                            <li>Click on a product you would love to order</li>
                                            <li>Right on the description page, click 'Add to cart'</li>
                                            <li>On the shopping cart page, click 'Proceed to checkout'</li>
                                            <li>Next, Fill in Your Details under 'Information'</li>
                                            <li>Carefully choose your preferred payment option</li>
                                            <li>Confirm your order by Clicking on 'Checkout'
                                            </li>
                                        </ol>
                                    </Accordion.Content>
                                </Accordion.Panel>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                    <h2 className="text-black dark:text-white "> How does the QUID Wallet system works for a seller?</h2> 
                                    </Accordion.Title>
                                    <Accordion.Content className="dark:bg-black">
                                    <p class="mb-2 text-black dark:text-white ">The QUID wallet system is used for sellers to store the value of sold products.</p>
                                    <p class="mb-2 text-black dark:text-white ">When a buyer makes a payment for a product, the money goes into the seller's wallet, and when the product has been received by the buyer, a code of delivery confirmation will be sent from the satisfied buyer to the seller. With this code, the seller now has full access to the money and is free to request a payout of the money into their bank account. It is simple...</p>
                                    <ul class="pl-5 text-black dark:text-white ">
                                        <li><b>SELL ITEMS</b></li>
                                        <li><b>RECEIVE MONEY</b></li>
                                        <li><b>REQUEST PAYOUT</b></li>
                                    </ul>
                                    <p class="mb-2 text-black dark:text-white ">In three easy steps, we have a happy seller.</p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                                <Accordion.Panel>
                                    <Accordion.Title>
                                    <h2 className="text-black dark:text-white ">Do i need to have a QuidPAY account as a buyer on QUID</h2> 
                                    </Accordion.Title>
                                    <Accordion.Content className="dark:bg-black">
                                        <p className="mb-2 text-black dark:text-white ">
                                            <p>
                                            Yes, you do. It is important that you fund your wallet either through a bank transfer or by using your debit card. This is necessary to prevent fraud, and ensure fast payment. It elevates your shopping experience.
                                            </p>
                                        </p>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            </Accordion>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faqs;