import { Disclosure } from '@headlessui/react'
import Switcher from './switcher'
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white dark:bg-black dark:text-white">
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <Link to={"/"} className="flex-shrink-0">
                      <img
                        className="h-8 w-30"
                        src="https://ik.imagekit.io/ffnwbq9cnh/prod/product_assets/logo_horizontal.png?updatedAt=1687430923433"
                        alt="Quid"
                      />
                    </Link>
                  </div>
                  <div className="block">
                    <div className="ml-4 flex items-center md:ml-6 space-x-1">
                      <Switcher/>
                      <h3>FAQS</h3>
                    </div>
                  </div>
                </div>
              </div>
        </Disclosure>
      </div>
    </>
  )
}
