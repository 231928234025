import React from "react";
import applestore from '../images/logo-apple-appstore.png'
import playstore from '../images/logo-google-playstore.png'
import mainn from '../images/mainn.png'

const Hero = () => {
    return (
        <section className="bg-secondery px-2 pt-15 lg:pt-20 dark:bg-black" id="contact">
            <div className="text-center md:w-[70%] mx-auto">
                <h2 className="text-3xl md:text-5xl lg:text-8xl text-heroheader font-black mb-2 mx-auto pb-2 leading-relaxed dark:text-white">
                    Virtual Marketplace for <br />Buyers and Sellers
                </h2>
                <p className="lg:text-2xl text-black mb-5 mx-auto pb-2 font-normal dark:text-white">
                    Faster and Easy Shopping Experience
                </p>
                <div className="flex space-x-1 items-center justify-center">
                    <a href=" https://play.google.com/store/apps/details?id=com.qiid.app" target='_blank'>
                        <button class="flex space-x-1 bg-white text-download text-left py-2 px-6 rounded-full border border-download dark:text-white dark:bg-about">
                            <img src={playstore} className="" />
                            <p className="text-xs lg:text-base md:text-base"> Download on <br /><b>Play Store</b></p>
                        </button>
                    </a>
                    <a href="https://apps.apple.com/ng/app/quid/id1661359725" target='_blank'>
                        <button class="flex space-x-1 bg-white text-download text-left py-2 px-6 rounded-full border border-download dark:text-white dark:bg-about">
                            <img src={applestore} />
                            <p className="text-xs lg:text-base md:text-base"> Download on <br /><b>Apple Store</b></p>
                        </button>
                    </a>
                </div>
                {/* <div className=" overflow-hidden block   w-2/3"> */}
                <img src={mainn} className="object-cover h-40 w-96 object-top  mx-auto items-center lg:h-96 lg:w-[100%] " />
                {/* </div> */}

                {/* <div className="flex overflow-hidden block  w-2/3">
                        <img src={right} className="h-28 w-80 object-cover object-top" />
                    </div> */}
            </div>
        </section>
    );
};

export default Hero;